import { Container } from '~/components/ui/Container'
import { LogoWithText } from '~/components/ui/logos/LogoWithText'
import { MARKETING_SITE_BASE_URL, ROUTES } from '~/lib/routes'
import { cn } from '~/lib/utils'

export function VCMIndexHeader() {
  return (
    <header role="banner">
      <div className="relative z-40 bg-transparent">
        <Container centered className="py-6">
          <nav className="flex justify-between items-center" aria-label="Main">
            {/* Left Abatable logo */}
            <div className="ml-0 mr-auto">
              <a href={MARKETING_SITE_BASE_URL}>
                <LogoWithText
                  width={137}
                  height={24}
                  className="text-brand-blue-500"
                />
              </a>
            </div>

            {/* CTA Button */}

            <a
              href={ROUTES.MARKETING_SITE.CONTACT_US}
              className={cn(
                'block px-5 py-2 rounded-lg bg-brand-neon-flame text-brand-blue-500 font-medium text-center sm:inline-block',
              )}
            >
              Contact
            </a>
          </nav>
        </Container>
      </div>
    </header>
  )
}
