import { Container } from '~/components/ui/Container'
import { LogoBlue } from '~/components/ui/logos/LogoBlue'

export function VCMIndexFooter() {
  return (
    <footer
      className="bg-brand-blue-500 text-brand-blue-100"
      role="contentinfo"
    >
      <Container centered className="py-8">
        <div className="sm:items-end sm:gap-4 flex flex-col sm:flex-row">
          <LogoBlue className="w-[30px] h-[28px]" />
          <div className="flex flex-col items-center sm:flex-row flex-wrap gap-2 sm:space-x-6 text-xs font-medium">
            <p className="mt-4 sm:mt-0">
              Copyright © {new Date().getFullYear()} Zero Imprint Ltd.{' '}
              <span className="block mt-4 sm:mt-0 sm:inline">
                All rights reserved.
              </span>
            </p>
            <div className="grid mt-4 sm:mt-0 grid-cols-2 gap-6">
              <a
                href="https://abatable.com/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                Terms and conditions
              </a>
              <a
                href="https://www.abatable.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
