import { VCMIndexFooter } from './components/VCMIndexFooter'
import { VCMIndexHeader } from './components/VCMIndexHeader'

import { Outlet } from '@remix-run/react'

export default function VCMLayout() {
  return (
    <div className="min-h-screen xs:bg-grey-100">
      <VCMIndexHeader />
      <Outlet />
      <VCMIndexFooter />
    </div>
  )
}
