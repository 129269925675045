import { SVGProps } from 'react'

export function LogoBlue(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Abatable_logo_brandblue_horizontal 1"
        clipPath="url(#clip0_115_34029)"
      >
        <path
          id="Vector"
          d="M9.54369 18.3697C8.09936 17.6513 7.15128 16.7947 6.36879 16.2007L4.65527 18.5979C5.29722 19.0384 6.05306 19.7493 8.03913 20.8127C9.96443 21.8435 11.9709 22.1347 13.2749 22.3934V19.4906C11.8306 19.232 10.988 19.0883 9.54369 18.3697Z"
          fill="#B2DFFF"
        />
        <path
          id="Vector_2"
          d="M19.9755 18.3697C21.4199 17.6513 22.3679 16.7947 23.1502 16.2007L24.8639 18.5979C24.222 19.0384 23.4662 19.7493 21.4801 20.8127C19.5548 21.8435 17.5481 22.1347 16.2441 22.3934V19.4906C17.6887 19.232 18.5312 19.0883 19.9755 18.3697Z"
          fill="#B2DFFF"
        />
        <path
          id="Vector_3"
          d="M0.351354 19.5598C-0.995789 21.281 1.24613 22.6539 1.60842 22.8998L13.262 6.54819V26.5675C13.262 27.5306 14.3274 27.797 14.7533 27.797V0.749512H14.7108C13.9608 0.749512 13.4324 1.1593 13.262 1.36419C9.46971 6.71224 1.65639 17.8925 0.351354 19.5598Z"
          fill="#B2DFFF"
        />
        <path
          id="Vector_4"
          d="M29.1539 19.5598C30.5012 21.281 28.2593 22.6539 27.897 22.8998L16.2434 6.54819V26.5675C16.2434 27.5306 15.178 27.797 14.752 27.797V0.749512H14.7946C15.5446 0.749512 16.0728 1.1593 16.2434 1.36419C20.0355 6.71224 27.8488 17.8925 29.1539 19.5598Z"
          fill="#B2DFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_115_34029">
          <rect
            width="30"
            height="27"
            fill="white"
            transform="translate(0 0.82666)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
